@font-face {
  font-family: 'Cocotte Webfont';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/cocotte-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Secession Webfont';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/secession-text.woff2') format('woff2');
}
